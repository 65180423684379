<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.21868 7.41094C5.2654 6.75681 5.8097 6.25 6.4655 6.25H17.5344C18.1902 6.25 18.7345 6.75681 18.7812 7.41094L19.7098 20.4109C19.7615 21.1345 19.1884 21.75 18.463 21.75H5.53693C4.8115 21.75 4.23842 21.1345 4.29011 20.4109L5.21868 7.41094ZM6.69828 7.75L5.80542 20.25H18.1945L17.3016 7.75H6.69828Z"
            fill="currentColor"
        />
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 3.75C10.7574 3.75 9.75 4.75736 9.75 6V10C9.75 10.4142 9.41421 10.75 9 10.75C8.58579 10.75 8.25 10.4142 8.25 10V6C8.25 3.92893 9.92893 2.25 12 2.25C14.0711 2.25 15.75 3.92893 15.75 6V10C15.75 10.4142 15.4142 10.75 15 10.75C14.5858 10.75 14.25 10.4142 14.25 10V6C14.25 4.75736 13.2426 3.75 12 3.75Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
